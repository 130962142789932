import { axiosWithToken } from "../axiosMiddleware";
import clientActions from "../clients/actions";
import requestsActions from "../requests/actions";
import contractorActions from "../contractors/actions";
import agentActions from "../agents/actions";
import planningActions from "../planning/actions";
import { isAdmin, isClient, isSubContractor } from "../../constants";
import moment from "moment";
import { Agent, User } from "./types";
import { notificationsActions } from "../notifications/actions";
import { chatActions } from "../chat/actions";
export const SERVICES = {
  CLIENT: "clients",
  BRANCH: "branches",
  LOCATION: "locations",
  CONTRACTOR: "subcontractors",
  REQUEST: "requests",
  REQUEST_EQUIPMENT: "request/equipment",
  EQUIPMENT: "equipment",
  PLANNING: "planning",
  PLANNING_HISTORY: "planning/history",
  SHIFT: "shifts",
  AGENT: "agents",
  LICENSE: "licenses",
  ACCOUNT: "accounts",
  NOTIFICATION: "notifications",
  CONTACT: "contacts",
};

export const fetchInitialData = async (dispatch: any, user: User) => {
  if (isAdmin) {
    const requests = await get(SERVICES.REQUEST);
    const clients = await get(SERVICES.CLIENT);
    const branches = await get(SERVICES.BRANCH);
    const locations = await get(SERVICES.LOCATION);
    const contractors = await get(SERVICES.CONTRACTOR);
    const agents = await get(SERVICES.AGENT);
    const equipment = await get(SERVICES.EQUIPMENT);
    const licenses = await get(SERVICES.LICENSE);
    dispatch(notificationsActions.fetchNotifications({ pageNumber: 1 }));
    dispatch(requestsActions.setRequests(requests));
    dispatch(requestsActions.setEquipment(equipment));
    dispatch(clientActions.setClients(clients));
    dispatch(clientActions.setBranches(branches));
    dispatch(clientActions.setLocations(locations));
    dispatch(contractorActions.setContractors(contractors));
    if (agents) {
      dispatch(
        agentActions.setAgents(agents.filter((agent: Agent) => !!agent.user))
      );
    }

    if (!isClient) {
      dispatch(chatActions.setCurrentChannel(null));
      dispatch(chatActions.getActiveChannels());
      dispatch(chatActions.getUnreadMessage());
    }

    dispatch(agentActions.setLicenses(licenses));
  } else if (isSubContractor) {
    const planning = await get(SERVICES.PLANNING, 0, {
      start: moment().format("YYYY-MM-DD 00:00"),
      end: moment().add(1, "M").format("YYYY-MM-DD 23:59"),
    });
    dispatch(planningActions.setPlanning(planning));

    const agents = await get(SERVICES.AGENT);
    dispatch(
      agentActions.setAgents(agents.filter((agent: Agent) => !!agent.user))
    );
    const licenses = await get(SERVICES.LICENSE);
    dispatch(agentActions.setLicenses(licenses));
  } else if (isClient) {
    const equipment = await get(SERVICES.EQUIPMENT);
    if (equipment) dispatch(requestsActions.setEquipment(equipment));
    const requests = await get(`${SERVICES.REQUEST}/own`);
    if (requests) dispatch(requestsActions.setRequests(requests));
    const locations = await get(`${SERVICES.LOCATION}/own`);
    if (locations) dispatch(clientActions.setLocations(locations));

    if (user.entity === "client") {
      const data = await get(`${SERVICES.CLIENT}/own`);
      if (data) dispatch(clientActions.setBranches(data.branches));
    }
  }
};
export const add = async (service: string, data: any) => {
  try {
    const response = await axiosWithToken.post(`/api/${service}`, data);
    return response?.data;
  } catch (e) {
    return null;
  }
};
export const get = async (service: string, id?: number, props?: any) => {
  try {
    const response = await axiosWithToken.get(
      `/api/${service}${id ? "/" + id : ""}`,
      {
        params: props,
      }
    );
    return response?.data;
  } catch (e) {
    return null;
  }
};

export const destroy = async (
  service: string,
  id: number | null,
  body?: any
) => {
  try {
    const response = await axiosWithToken.delete(
      `/api/${service}${id ? "/" + id : ""}`,
      { data: body }
    );
    return response?.data;
  } catch (e) {
    return null;
  }
};

export const update = async (
  service: string,
  id: number | string | null,
  data: any
) => {
  try {
    const response = await axiosWithToken.put(
      `/api/${service}${id ? "/" + id : ""}`,
      data
    );
    return response?.data;
  } catch (e) {
    return null;
  }
};

export const getRemoteImageURL = (url: string) => {
  return axiosWithToken
    .get(url, {
      responseType: "arraybuffer",
      headers: {
        Accept: "*/*",
      },
    })
    .then(async (res: any) => {
      const blob = new Blob([res.data], {
        type: "image/jpeg",
      });

      const objectURL = URL.createObjectURL(blob);
      return objectURL;
    });
};
