import React from "react";
import { AxiosResponse } from "axios";
import { REQUEST_STATUS } from "../constants";
import moment from "moment";
import { getRemoteImageURL, SERVICES } from "./apis/global";
import { BASE_PATH } from "./axiosMiddleware";
export const isSuccessResponse = ({ status }) => status >= 200 && status < 400;

export const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const polylinecolors = ["#b71c1c", "#0d47a1", "#1b5e20", "#263238"];

export const getActiveTabStatus = (index) => {
  switch (index) {
    case 0:
      return REQUEST_STATUS.PENDING;
    case 1:
      return REQUEST_STATUS.ACCEPTED;
    case 2:
      return REQUEST_STATUS.REJECTED;
    case 3:
      return REQUEST_STATUS.CANCELED;
    case 4:
      return REQUEST_STATUS.FINISHED;
    case 5:
      return REQUEST_STATUS.CHECKED;
    case 6:
      return REQUEST_STATUS.ARCHIVED;
    default:
      return REQUEST_STATUS.PENDING;
  }
};
export const getClientActiveTabStatus = (index) => {
  switch (index) {
    case 0:
      return REQUEST_STATUS.PENDING;
    case 1:
      return REQUEST_STATUS.ACCEPTED;
    case 2:
      return REQUEST_STATUS.REJECTED;
    case 3:
      return REQUEST_STATUS.CANCELED;
    case 4:
      return REQUEST_STATUS.CHECKED;
    case 5:
      return REQUEST_STATUS.ARCHIVED;
    default:
      return REQUEST_STATUS.PENDING;
  }
};

export const getActiveLocations = (locations, shift, agent, team) => {
  if (agent) return locations.filter((x) => x.id == agent.team_id);
  if (team) return locations.filter((x) => x.id == team.id);
  if (shift && shift.id) return locations.filter((x) => x.shift_id == shift.id);

  return locations;
};
export const getMessageDate = (date) => {
  const formattedDate = moment(date).format("YYYY/MM/DD HH:mm");
  let dateToday = new Date().toDateString();
  let longDateYesterday = new Date();
  longDateYesterday.setDate(new Date().getDate() - 1);
  let dateYesterday = longDateYesterday.toDateString();
  let today = dateToday.slice(0, dateToday.length - 5);
  let yesterday = dateYesterday.slice(0, dateToday.length - 5);

  const wholeDate = new Date(formattedDate + " UTC").toDateString();

  const messageDateString = wholeDate.slice(0, wholeDate.length - 5);

  if (
    new Date(formattedDate + " UTC").getFullYear() === new Date().getFullYear()
  ) {
    if (messageDateString === today) {
      return moment.utc(date).local().format("HH:mm");
    } else if (messageDateString === yesterday) {
      return "Yesterday";
    } else {
      return messageDateString;
    }
  } else {
    return wholeDate;
  }
};

// {
//   position: "left",
//   avatar:
//     "https://spng.pngfind.com/pngs/s/5-52097_avatar-png-pic-vector-avatar-icon-png-transparent.png",
//   type: "text",
//   text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
//   date: new Date(),
//   title: "Elit magnaaa",
//   avatarFlexible: true,
// }

export const isImage = (image) => {
  return image.match(
    "[^\\s]+(.*?)\\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF|svg|SVG)$"
  );
};
export const getMessageType = (message) => {
  if (!message.user) return "system";
  if (!message.image) return "text";
  if (isImage(message.image)) return "photo";
  return "file";
};

export const getLastMessageText = (message, user) => {
  const userMessageStatus = message.message_status?.find(
    (x) => x.user_id == user.id
  );
  const messageType = getMessageType(message);
  if (messageType === "text" && userMessageStatus?.tr_text) {
    return userMessageStatus?.tr_text;
  }

  if (message.tr_text) return message.tr_text;

  return message.text;
};

export const giftedChatType = async (messages, currentChannel, user) => {
  const copyMessages = [...messages];
  const data = [];
  for (let i = 0; i < copyMessages.length; i++) {
    const message = copyMessages[i];
    message.position =
      message.user && message.user.id == user.id ? "right" : "left";
    const messageType = getMessageType(message);
    message.type = messageType;
    const userMessageStatus = message.message_status?.find(
      (x) => x.user_id == user.id
    );
    if (messageType === "text" && userMessageStatus?.tr_text) {
      message.tr_text = userMessageStatus?.tr_text;
    }
    message.text = messageType === "file" ? message.image : message.text;

    if (
      message.tr_text &&
      message.text &&
      messageType === "text" &&
      message.text != message.tr_text &&
      typeof message.text === "string"
    ) {
      message.text = (
        <div key={message.id}>
          <div style={{ fontSize: 12, fontStyle: "italic", fontWeight: 400 }}>
            original: {message.text}
          </div>
          <div>{message.tr_text}</div>
        </div>
      );
    }

    // message.avatar =
    //   "https://spng.pngfind.com/pngs/s/5-52097_avatar-png-pic-vector-avatar-icon-png-transparent.png";
    message.date = new Date(moment.utc(message.created_at).local());
    if (message.user) {
      message.title = `${message.user.first_name} ${message.user.last_name}`;
      if (message.user.id == user.id) {
        message.status = !message.message_status
          ? "waiting"
          : message.message_status && message.message_status.length > 0
          ? "sent"
          : "";
      }

      message.sent =
        message.message_status && message.message_status.length > 0;
    } else {
      message.system = true;
    }
    if (message.image && currentChannel) {
      const request = currentChannel.channels.request;
      const remoteURL = await getRemoteImageURL(
        `${BASE_PATH}/api/${SERVICES.PLANNING_HISTORY}/image/${request.id}/${message.image}`
      );
      message.lazyLoadingImage = remoteURL;
      // message.photo = message.lazyLoadingImage;
      // message.image = message.lazyLoadingImage;
      message.onClick = () => {
        console.log("onclick image");
      };
      message.data = {
        uri: message.lazyLoadingImage,
        status: {
          click: true,
          loading: 0,
        },
      };
    }

    // message.image = message.image
    //   ? `${Config.IMAGE_URL}requests/'${request.type}_${request.identifier}/${message.image}`
    //   : null;
    // message.received =
    //   message.message_status &&
    //   message.message_status.filter((x) => x.status > 0).length > 0
    //     ? true
    //     : false;
    copyMessages[i] = message;
    data.push(message);
  }
  return data.reverse();
};

export const getRequestStatus = (request) => {
  return request.status === "confirmed" ? "active" : "recent"; //confirmed;
};
